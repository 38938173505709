import React, { useContext, useEffect, useState } from 'react';
import User from '../components/Utils';
import MenuBar from '../components/Menubar';
import LoaderContext from '../components/LoaderContext';
import { Link } from 'react-router-dom';

type Props = {
  user: User,
  handleLogin(user: any): void
};

const ClosedRestaurantsPage: React.FC<Props> = (props) => {
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [restaurants, setRestaurants] = useState<any[]>([]);
  const [showTable, setShowTable] = useState<boolean>(false);

  const handleNotification =  async () =>
  {
    try {
      const stored = localStorage.getItem('YoloMeal');
      console.log(stored);
      if (stored) {
        showLoader();
        const userData = JSON.parse(stored);

        fetch(
          process.env.REACT_APP_SERVER_URL +
          `/markedClosedRestaurant` ,
          {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + userData.token,
            })
          }
        )
          .then((res) => res.json())
          .then(
            (result) => {
              setRestaurants(result.restaurants);
              setShowTable(true);
              hideLoader();
            },
            (error) => {
              hideLoader();
              console.log(error)
            }
          );

      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  return(
    <main>
      <div className="container mypage">
        <MenuBar user={props.user} />
        <section>
          <div className="page-box-main">
            {props.user.isLogged && props.user.id === 514 &&
              <h2 className="futuralt-book pt-2 mb-4 text-center f-22">
                <button className={"see-restaurant-button"} onClick={handleNotification}> Click Here for the Restaurants
                  that Meet the Threshold '5'
                </button>
              </h2>
            }

            {showTable && restaurants.length > 0 && (
              <table className="shares-table" style={{ width: '115%' }}>
                <thead>
                <tr>
                  <td><strong>Restaurant</strong></td>
                  <td><strong>Action</strong></td>
                </tr>
                </thead>
                <tbody>
                {restaurants.map((restaurant: any, index) => (
                  <tr key={index}>
                    <td>{restaurant.name}</td>
                    <td>
                      <Link to={`/restaurentDetail/${restaurant.id}?name=${encodeURIComponent(restaurant.name)}`}
                            className='select'>Select</Link>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            )}
          </div>
          <div className="page-box-main">
            <button className={"see-restaurant-button black justify-content-center"}>
              <Link to="/admin">Back</Link>
            </button>
          </div>
        </section>
      </div>
    </main>
  );
}

export default ClosedRestaurantsPage;