import { useEffect, useState } from 'react';
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';
import Tracker from '@openreplay/tracker';

import './App.css';

import LoaderContext from './components/LoaderContext';
import User from './components/Utils';
import StartPage from './pages/StartPage';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import MyPage from './pages/MyPage';
import UserPage from './pages/UserPage';
import SettingsPage from './pages/SettingsPage';
import NotificationsPage from './pages/NotificationsPage';
import ChangeIdentityPage from './pages/ChangeIdentityPage';
import FaqPage from './pages/FaqPage';
import TermsPage from './pages/TermsPage';
import PolicyPage from './pages/PolicyPage';
import ReferralPage from './pages/ReferralPage';
import RestaurantPage from './pages/RestaurantPage';
import EatPage from './pages/EatPage';
import DrinksPage from './pages/DrinksPage';
import EatYolosPage from './pages/EatYolosPage';
import AddYoloPage from './pages/AddYoloPage';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import AdminPage from './pages/AdminPage';
import RestaurantSearch from './pages/RestaurantSearch';
import AccountSettingPage from './pages/AccountSettingPage';
import ShareHoldersPage from './pages/shareholdersPage';
import ClosedRestaurantsPage from './pages/ClosedRestaurantsPage'
import NewMemberPage from './pages/newMemberPage';
import RecentYollosPage from './pages/recentYollsPage';
import AdminRestorentPage from './pages/adminRestaurentPage';
import AdminRestorentDetailPage from './pages/adminRestaorentsDetailPage';
import FirstSplashScreen from './pages/FirstSplashScreen';
import SecondSplahScreen from './pages/SecondSplahScreen';
import LoginPagev2 from './pages/LoginPagev2';
import VerificationSuccess from './pages/VerificationSuccess';
import DisclaimerPage from './pages/DisclaimerPage';


function App() {
  const [user, setUser] = useState<User>({ isLogged: false, id: -1, name: '', email: '', token: '', expires: 0 });
  const [location, setLocation] = useState({ lat: 0, lng: 0, name: '' });
  const [loading, setLoading] = useState(true);
  const [openReplayTracker, setOpenReplayTracker] = useState<any>(null);
  const hideLoader = () => setLoading(false);
  const showLoader = () => setLoading(true);
  const [userInfo, setUserInfo] = useState<any>(null);

  useEffect(() => {
    const tracker = new Tracker({
      projectKey: 'DA2gV3NaLuEAJa8Jc4vM',
    });
    setOpenReplayTracker(tracker);
  }, []);

  const newLocation = async (location, position) => {
    log(location['long_name']);
    const obj = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
      name: location,
    };
    log(obj);
    setLocation(obj);
  };

  const log = async (message: any) => {
    if (process.env.REACT_APP_LOG === 'true') {
      console.log(message);
    }
  };

  useEffect(() => {
    console.log(process.env.REACT_APP_VERSION);
    log(process.env.REACT_APP_SERVER_URL);
    loadUser();
    //const googleMapScript = loadMapApi();
    setLoading(true);
    log('loading google maps...');
    const googleMapScript = document.createElement('script');
    googleMapScript.src =
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyBhVpChsNDfRuKCmBZ2A1qqtjVxW7nDY8c&libraries=places&v=3';
    googleMapScript.async = true;
    window.document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener('load', () => {
      log('map loaded');
      setLoading(false);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position: any) => {
            log(position);

            const latlng = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ location: latlng }, (results, status) => {
              if (status === google.maps.GeocoderStatus.OK) {
                console.log(results);
                if (results[0] && results[0]['address_components']) {

                  const addressComponents = results[0]['address_components'];
                  let fullName : String = '';

                  const country = addressComponents.filter(component =>
                    component['types'].includes('country')
                  ).map(component => {
                    return component;
                  });

                  const state = addressComponents.filter(component =>
                    component['types'].includes('administrative_area_level_1')
                  ).map(component => {
                    return component;
                  });

                  const locality = addressComponents.filter(component =>
                    component['types'].includes('locality')
                  ).map(component => {
                    return component;
                  });

                  if (locality.length > 0){
                    fullName = `${locality[0].long_name}, ${state[0].short_name}, ${country[0].short_name}`
                    newLocation(fullName, position);
                    return;
                  }

                  const administrativeArea = addressComponents.filter(component =>
                    component['types'].includes('administrative_area_level_2')
                  ).map(component => {
                    return component;
                  });

                  if (administrativeArea.length > 0) {
                    fullName = `${administrativeArea[0].long_name}, ${state[0].short_name}, ${country[0].short_name}`
                    newLocation(fullName, position);
                    return;
                  }
                } else {
                  log('Geocode was not successful for the following reason: ' + status);
                }
              }
            });
          },
          () => {
            log('error');
          }
        );
      } else {
        // Browser doesn't support Geolocation
        log('Not suppported');
      }
    });
  }, []);

  const loadUser = () => {
    log('Reading from session...');
    const stored = localStorage.getItem('YoloMeal');
    log(stored);
    if (stored) {
      const userData = JSON.parse(stored);
      const expires = new Date(userData.expires);
      log(expires);
      const now = new Date();
      if (expires > now) {
        setUser({
          isLogged: true,
          id: userData.id,
          name: userData.name,
          email: userData.email,
          token: userData.token,
          expires: userData.expires,
        });
      }
    }
  };

  const handleLogin = async (user) => {
    console.log(user);
    setUser({ isLogged: true, id: user.id, name: user.name, email: user.email, token: user.token, expires: user.expires });
    log('Saving to session...');
    localStorage.setItem('YoloMeal', JSON.stringify(user));
  };

  const onLoginBackClick = async () => {};

  const handleLogout = async () => {
    setUser({ isLogged: false, id: -1, name: '', email: user.email, token: '', expires: 0 });
    localStorage.setItem('YoloMeal', '');
  };

  const handleLocationChange = async (obj) => {
    setLocation(obj);
    log(obj);
  };

  useEffect(() => {
    // Check if login information exists in local storage
    let storedUser = localStorage.getItem("letsgo");
    if (storedUser) {
      setUserInfo(storedUser);
    }
  }, []);

  /*
  const loadMapApi = () => {
    console.log('loading google maps...');
    const mapsURL = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBhVpChsNDfRuKCmBZ2A1qqtjVxW7nDY8c&libraries=places&v=3";
    const scripts = document.getElementsByTagName('script');
    // Go through existing script tags, and return google maps api tag when found.
    for (let i = 0; i < scripts.length; i++) {
        if (scripts[i].src.indexOf(mapsURL) === 0) {
            return scripts[i];
        }
    }

    const googleMapScript = document.createElement('script');
    googleMapScript.src = mapsURL;
    googleMapScript.async = true;
    googleMapScript.defer = true;
    window.document.body.appendChild(googleMapScript);

    return googleMapScript;
  };
  */

  return (
    <LoaderContext.Provider value={{ loading, hideLoader, showLoader }}>
      {loading && (
        <div className="loader" id="loader">
          <div className="loader__holder">
            <h3>Loading...</h3>
            <div className="cover-spin"></div>
          </div>
        </div>
      )}
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <Route path="/home">
            {!loading && (
              <HomePage user={user} location={location} handleLocationChange={handleLocationChange} />
            )}
          </Route>
          <Route path="/about">
            <AboutPage user={user} />
          </Route>
          {/*<Route path="/referral">*/}
          {/*  */}
          {/*  <ReferralPage user={user} />*/}
          {/*</Route>*/}
          <Route path="/my-page">
            <MyPage user={user} handleLogin={handleLogin} />
          </Route>
          <Route path="/user/:id">
            <UserPage user={user} handleLogin={handleLogin} />
          </Route>
          <Route path="/settings">
            <SettingsPage user={user} />
          </Route>
          <Route path="/notifications">
            <NotificationsPage user={user} />
          </Route>
          <Route path="/change-identity">
            <ChangeIdentityPage user={user} />
          </Route>
          <Route path="/faq">
            <FaqPage user={user} />
          </Route>
          <Route path="/terms">
            <TermsPage user={user} />
          </Route>
          <Route path="/policy">
            <PolicyPage user={user} />
          </Route>
          <Route path="/login">
            <LoginPage
              user={user}
              handleLogin={handleLogin}
              showBack={false}
              showHome={true}
              backgroundImage={true}
              handleBack={onLoginBackClick}
            />
          </Route>
          <Route path="/logout">
            <LogoutPage user={user} handleLogout={handleLogout} />
          </Route>
          <Route path="/restaurant/:id">
            <RestaurantPage user={user} handleLogin={handleLogin} restaurantPage={true} />
          </Route>
          <Route path="/add-yolo">
            <AddYoloPage
              user={user}
              location={location}
              handleLogin={handleLogin}
              handleLogout={handleLogout}
              handleLocationChange={handleLocationChange}
            />
          </Route>
          <Route path="/search-restaurant">
            {!loading && (
              <RestaurantSearch user={user} location={location} />
            )}
          </Route>
          <Route path="/eat">
            <EatPage user={user} />
          </Route>
          <Route path="/drinks">
            <DrinksPage user={user} />
          </Route>
          <Route path="/entree">
            <EatYolosPage
              name="Entree"
              id={1}
              location={location}
              user={user}
              handleLogin={handleLogin}
              eatOrMeal="eat"
            />
          </Route>
          <Route path="/burgers">
            <EatYolosPage
              name="Burgers"
              id={2}
              location={location}
              user={user}
              handleLogin={handleLogin}
              eatOrMeal="eat"
            />
          </Route>
          <Route path="/pizza">

            <EatYolosPage
              name="Pizza"
              id={3}
              location={location}
              user={user}
              handleLogin={handleLogin}
              eatOrMeal="eat"
            />
          </Route>
          <Route path="/apps">
            <EatYolosPage
              name="Appetizer"
              id={4}
              location={location}
              user={user}
              handleLogin={handleLogin}
              eatOrMeal="eat"
            />
          </Route>
          <Route path="/sides">
            <EatYolosPage
              name="Sides"
              id={5}
              location={location}
              user={user}
              handleLogin={handleLogin}
              eatOrMeal="eat"
            />
          </Route>
          <Route path="/dessert">
            <EatYolosPage
              name="Dessert"
              id={6}
              location={location}
              user={user}
              handleLogin={handleLogin}
              eatOrMeal="eat"
            />
          </Route>
          <Route path="/breakfast">
            <EatYolosPage
              name="Breakfast"
              id={7}
              location={location}
              user={user}
              handleLogin={handleLogin}
              eatOrMeal="eat"
            />
          </Route>
          <Route path="/handheld">
            <EatYolosPage
              name="handheld"
              id={13}
              location={location}
              user={user}
              handleLogin={handleLogin}
              eatOrMeal="eat"
            />
          </Route>
          <Route path="/wine">
            <EatYolosPage
              name="Wine"
              id={8}
              location={location}
              user={user}
              handleLogin={handleLogin}
              eatOrMeal="drink"
            />
          </Route>
          <Route path="/mixed-drinks">
            <EatYolosPage
              name="Mixed Drinks"
              id={9}
              location={location}
              user={user}
              handleLogin={handleLogin}
              eatOrMeal="drink"
            />
          </Route>
          <Route path="/beer">
            <EatYolosPage
              name="Beer"
              id={10}
              location={location}
              user={user}
              handleLogin={handleLogin}
              eatOrMeal="drink"
            />
          </Route>
          <Route path="/coffee">
            <EatYolosPage
              name="Coffee"
              id={11}
              location={location}
              user={user}
              handleLogin={handleLogin}
              eatOrMeal="drink"
            />
          </Route>
          <Route path="/other-drinks">
            <EatYolosPage
              name="Other Drinks"
              id={12}
              location={location}
              user={user}
              handleLogin={handleLogin}
              eatOrMeal="drink"
            />
          </Route>
          <Route path="/admin">
            <AdminPage user={user} handleLogin={handleLogin} />
          </Route>
          <Route path="/shareholders">
            <ShareHoldersPage user={user} handleLogin={handleLogin} />
          </Route>
          <Route path="/newMembers">
            <NewMemberPage user={user} handleLogin={handleLogin} />
          </Route>
          <Route path="/recentYollos">
            <RecentYollosPage user={user} handleLogin={handleLogin} />
          </Route>
          <Route path="/markedClosedRestaurant">
              <ClosedRestaurantsPage user={user} handleLogin={handleLogin} />
          </Route>
          <Route path="/restaurent">
            <AdminRestorentPage user={user} handleLogin={handleLogin} location={location} handleLocationChange={handleLocationChange} />
          </Route>
          <Route path="/restaurentDetail/:id">
            <AdminRestorentDetailPage user={user} handleLogin={handleLogin}/>
          </Route>
          <Route path="/account">
            <AccountSettingPage user={user} handleLogin={handleLogin}></AccountSettingPage>
          </Route>
          <Route path="/contest1">
            <FirstSplashScreen></FirstSplashScreen>
          </Route>
          <Route path="/contest2">
            <SecondSplahScreen></SecondSplahScreen>
          </Route>
          <Route path="/loginv2">
            <LoginPagev2
              user={user}
              emailVerified={false}
              handleLogin={handleLogin}
              showHome={true}
              handleBack={onLoginBackClick}
              success={()=>{}}
            />
          </Route>
          <Route path="/verification-success">
            <VerificationSuccess
            user={user}
            handleLogin={handleLogin}
            handleBack={onLoginBackClick}
            />
          </Route>
          <Route path="/contest_disclaimer_and_conditions">
            <DisclaimerPage></DisclaimerPage>
          </Route>
        </Switch>
      </BrowserRouter>
    </LoaderContext.Provider>
  );
}

export default App;
