import React, { useContext, useEffect, useState } from 'react';
import User from '../components/Utils';
import LoaderContext from '../components/LoaderContext';
import MenuBar from '../components/Menubar';
import { Link } from 'react-router-dom';
type Props = {
    user: User,
    handleLogin(user: any): void
};
const RecentYollosPage: React.FC<Props> = (props) => {
    const [error, setError] = useState('');
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const [items, setItems] = useState([]);
    const [showTable, setShowTable] = useState<boolean>(false);
    let didCancel = false;
    useEffect(() => {
        if (props.user.isLogged) {
            console.log("recent");

        }

        return () => {
            didCancel = true;
        };
    }, [props.user.isLogged]);
    const log = async (message: any) => {
        if (process.env.REACT_APP_LOG === 'true') {
            console.log(message);
        }
    }
    const handleErrors = (response) => {
        log(response);
        if (!response.ok) {
            var err = response.statusText;
            if (err === '') {
                err = 'Error';
            }
            throw Error(err);
        }
        return response;
    }

    const loadData = async () => {
        showLoader();
        fetch(process.env.REACT_APP_SERVER_URL + "/recentYolos", {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                "Accept": "application/json",
                'Authorization': 'Bearer ' + props.user.token
            }),
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(
                (result) => {
                    log(result);
                    if (!didCancel) {
                        hideLoader();
                        setItems(result.recentYolos);
                    }
                },
                (error) => {
                    log(error);
                    if (!didCancel) {
                        hideLoader();
                        setError(error.message);
                    }
                }
            )
    };
    const handleGetData = () => {
        // Code to fetch data
        // Assuming fetchData is an asynchronous function that fetches data
        loadData().then(() => {
            setShowTable(true);
        }).catch((error) => {
            console.error('Error fetching data:', error);
        });
    };

    if (!props.user.isLogged || (props.user.id !== 6 && props.user.id !== 7 && props.user.id !== 514)) {
        return (
            <div>
                <div>Access denied.</div>
            </div>
        )
    } else {
        return (
            <main>
                <div className="container mypage">
                    <MenuBar user={props.user} />
                    <section>
                        <div className="page-box-main">
                            {props.user.isLogged && props.user.id === 514 &&
                                <h2 className="futuralt-book pt-2 mb-4 text-center f-22">
                                    <button className="next-button"> Recent Yollos </button>
                                </h2>
                            }
                            {error !== '' &&
                                <p>{error}</p>
                            }
                            <div>
                                <div className='button-getYollos'>
                                    <button className='' onClick={handleGetData}>GET DATA</button>
                                </div>
                                {showTable && (
                                    <>
                                     <div className="table-container">
                                        <table className="shares-table">
                                            <thead>
                                                <tr>
                                                    <td><strong>Name</strong></td>
                                                    <td><strong>Category</strong></td>
                                                    <td><strong>Menu Item</strong></td>
                                                    <td><strong>Restaurant Name</strong></td>
                                                    <td><strong>Email</strong></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {items.length <= 0 ? (
                                                    <tr>
                                                        <td colSpan={5} style={{ textAlign: 'center' }}>No items found</td>
                                                    </tr>
                                                ) : (
                                                    items.map((item, i) => (
                                                        <tr key={i}>
                                                            <td>{item['Username']}</td>
                                                            <td>{item['categoryName']}</td>
                                                            <td>{item['itemName']}</td>
                                                            <td>{item['restaurantName']}</td>
                                                            <td>{item['email']}</td>
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                        </div>
                                        <div>
                                            <button className="next-button black">
                                                <Link to="/admin">Back</Link>
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>


                        </div>
                    </section>

                </div>
            </main>
        );
    }
}
export default RecentYollosPage;