import React from 'react';
import MenuBar from '../components/Menubar';
import LoginPagev2 from './LoginPagev2';
import { useHistory } from 'react-router-dom';

type Props = {
  user: any;
  handleLogin(user: any): void;
  handleBack(): void;
};

const defaultProps: Props = {
  user: null,
  handleLogin: () => {},
  handleBack: () => {},
};



const VerificationSuccess: React.FC<Props> = (props) => {

  const history = useHistory();

  const handleLoginSuccess = () =>{
    history.push('/home');
  }

  return(
    <main className={'main-bg'}>
      <div className="container">
        <MenuBar user={props.user} />
      </div>
      <div className="small-container">
        <div className="startpage-content">
          <div className="start-row d-flex full-height align-items-center">
            <div className="about-content terms-content faq-content mb-4 pb-4 f-15">
              <div className="terms-box bg-white">
                <p>The email has been verified successfully. You can continue with login process.</p>
              </div>
              <LoginPagev2 user={props.user}
                 emailVerified={true}
                 showHome={false}
                 success={handleLoginSuccess}
                 handleLogin={props.handleLogin}
                 handleBack={props.handleBack}/>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

VerificationSuccess.defaultProps = defaultProps;
export default VerificationSuccess;