import React, { useContext, useState } from 'react';
import Modal from 'react-modal';
import LoaderContext from '../components/LoaderContext';

const customStyles = {
  content: {
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    border: 0,
    background: '#f9c5b4'
  },
};

type Props = {
  isOpen: boolean;
  handleModalClose(): void;
  user: any
  yoloId: number
  onDelete(): void
};

const DeleteModal: React.FC<Props> = (props) => {

  const onDelete = async () => {
   props.onDelete();
  }

  const closeModel = ()=>{
    props.handleModalClose();
  }

  return (
    <Modal isOpen={props.isOpen} ariaHideApp={false} style={customStyles}>
      <div className="container bg-orange">
        <section>
          <div className="small-container add-yolo-info text-center">
            <div className="yolo-container">
              <h2 className="futuralt-book mt-4 pt-2 mb-4 text-center f-22">DELETE YOLO</h2>
              <div className="md-container text-center">
                <h3 className="futuralt-book f-24 mt-4 pt-4 mb-4 text-center"><br />Are you sure?</h3><br />
              </div>
            </div>
            <div className="mt-0 mb-4 d-flex-buttons black justify-content-space-between align-items-center uppercase">
              <h1>
                <button className="next-button" onClick={closeModel}> BACK</button>
              </h1>
              <h1>
                <button className="next-button" onClick={onDelete}> DELETE</button>
              </h1>
            </div>
          </div>
        </section>
      </div>
    </Modal>
  )
}

export default DeleteModal;
